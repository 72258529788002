.home-form {
   width: 100%;
   background-color: #505e7d;
   padding: 6% 2%;
   display: flex;
   flex-direction: column;
   gap: 30px;
   justify-content: center;
   align-items: center;

}

.home-form-header {
   color: white;
   display: flex;
   gap: 15px;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   letter-spacing: 0.5px;
}

.home-form-section {
   background-color: #fff;
   display: flex;
   flex-direction: column;
   gap: 50px;
   padding: 5%;
   width: 100%;

}

.home-form-inputs {
   display: flex;
   gap: 20px;
   align-items: center;
   justify-content: space-around;
}

.home-form-inputs>input {
   width: 35%;
   padding: 1.2%;
   font-size: 15px;
   border: 1px solid lightgray;
}

.home-form-picker>.input {
   width: 100%;
   height: 200px;
   padding: 1.2%;
   border: 1px solid lightgray;
}

.home-form-button {
   width: 15%;
   text-align: center;
   top: 50%;
   left: 50%;
   padding: 1.5%;
   background-color: #5abb1a;
   color: #fff;
   letter-spacing: 1px;
   border: none;
}

.home-form-center {
   width: 100%;
   display: flex;
   justify-content: center;
   flex-direction: column;
   align-items: center;
   gap: 70px;
}

.home-form-header-one {
   font-size: 1em;
   font-weight: bolder;
}

.home-form-header-two {
   font-size: 3em;
   font-weight: 700;
}

.home-form-header-three {
   font-size: 1e.2m;
   font-weight: bolder;
   color: #5abb1a
}


.horizontal-rule-white {
   display: flex;
   align-items: center;
   text-align: center;
   gap: 2px;
}

.line {
   width: 60px;
   height: 4px;
   background-color: #fff;
   border-color: #fff;
}

.horizontal-rule-dotted-white {
   height: 4px;
   background-color: #fff;
   width: 5px;
   border-color: #fff;
}

.home-form-footer {
   display: flex;
   flex-direction: column;
   align-items: center;
   gap: 20px;
}

.home-form-footer-one {
   font-size: 14px;
   font-weight: 600;
   letter-spacing: 0.2px;
}

.home-form-footer-two {
   font-size: 30px;
   display: flex;
   align-items: center;
   gap: 10px;
}

.number {
   color: rgb(69, 69, 184)
}

@media screen and (max-width: 700px) {
   .home-form-header-two {
      font-size: 2em;
   }

   .home-form-header-three {
      font-size: 1em;
   }

   .home-form-inputs {
      display: flex;
      flex-direction: column;
   }

   .home-form-inputs>input {
      width: 100%;
      padding: 3%;
   }

   .home-form-picker>.input {
      padding: 3%;
      width: 100%;
   }

   .home-form-button {
      width: 50%;
      padding: 5% 0;
   }

   .home-form-footer-one {
      font-size: 12px;
   }

   .home-form-footer-two {
      display: flex;
      flex-direction: column;
      font-size: 18px;
   }

}