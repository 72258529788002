*,
::after,
::before {
   margin: 0;
   padding: 0;
   box-sizing: border-box;
}

.Navbar {
   position: fixed;
   top: 1%;
   width: 100%;
   height: 6em;
   display: flex;
   justify-content: space-around;
   align-items: center;
   background: #ebe6e6;
   z-index: 1;
   color: lightgray;
   box-shadow: 2px 2px 5px 5px rgba(0, 0, 0, 0.5);
}

.nav-items {
   display: flex;
   gap: 40px;
   color: lightgray;
   letter-spacing: 0.5px;
   font-weight: 550;
}

.nav-logo {
   display: flex;
   align-items: center;
   gap: 10px;
   color: rgb(37, 36, 36);

}

.nav-logo>.logo {
   text-decoration: none;
   color: rgb(37, 36, 36);

}

.logo>span {
   font-size: 28px;
   font-weight: 900;
   color: #52c306;
   cursor: pointer;
}

.nav-logo>span:hover {
   color: white;
}

.Navbar>.nav-items>a {
   display: flex;
   font-size: 18px;
   text-decoration: none;
   margin: 15px;
   position: relative;
   opacity: 0.9;
   padding-bottom: 2px;
   color: rgb(37, 36, 36);
}

.Navbar>.nav-items>a:hover {
   opacity: 1;
   color: #52c306;
   transition: ALL ease-in-out;
   transition-duration: 0.2s;
}

.Navbar>.nav-items>a::before {
   content: "";
   position: absolute;
   left: 0;
   bottom: -15%;
   width: 0;
   height: 3px;
   background: #ffffff;
   transition: all 0.45s;
}

.Navbar>.nav-items>.a:hover::before {
   width: 100%;
}

.Navbar>.nav-toggle {
   display: none;
}

@media screen and (max-width: 700px) {
   .Navbar {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 64px;
      width: 100%;
      background: #ebe6e6;
      box-shadow: 2px 2px 5px 5px rgba(0, 0, 0, 0.5);
      padding: 0 5px;

   }

   .Navbar>.nav-items {
      position: fixed;
      top: 0;
      left: 0;
      display: flex;
      flex-direction: column;
      background: #444444;
      width: 100%;
      height: 100%;
      padding-top: 8em;
      transform: translateY(-100%);
      transition: all 0.45s;
      color: white;
   }

   .Navbar>.nav-items>a {
      font-size: 3.5em;
      font-weight: 900;
      opacity: 1;
      color: #ffffff;
   }

   .Navbar>.nav-items>a::before {
      background: transparent;
   }

   .Navbar>.nav-items.open {
      transform: translateY(0);
   }

   .Navbar>.nav-toggle {
      display: flex;
      width: 50px;
      height: 50px;
      align-items: center;
      justify-content: center;
      cursor: pointer;
   }

   .nav-toggle>.bar {
      position: relative;
      width: 32px;
      height: 2px;
      background: rgb(37, 36, 36);
      transition: all 0.45s ease-in-out;
   }

   .nav-toggle>.bar::before,
   .nav-toggle>.bar::after {
      content: "";
      position: absolute;
      height: 2px;
      background: rgb(37, 36, 36);
      border-radius: 2px;
      transition: all 0.45s ease-in-out;
   }

   .nav-toggle>.bar::before {
      width: 25px;
      transform: translateY(-8px);
      right: 0;
   }

   .nav-toggle>.bar::after {
      width: 32px;
      transform: translateY(8px);
   }

   .nav-toggle.open>.bar {
      transform: translateX(-40px);
      background: transparent;
   }

   .nav-toggle.open>.bar::before {
      width: 32px;
      transform: rotate(45deg) translate(26px, -26px);
   }

   .nav-toggle.open>.bar::after {
      transform: rotate(-45deg) translate(26px, 26px);
   }
}

@keyframes icons {
   0% {
      transform: translateY(0px);
   }

   50% {
      transform: translateY(-8px);
   }

   100% {
      transform: translateY(0px);
   }
}