.about-team {
   display: flex;
   flex-direction: column;
   padding: 30px 50px;
   gap: 50px;
}

.about-team-text {
   display: flex;
   flex-direction: column;
   gap: 15px;
}

.about-team-header {
   font-size: 40px;
   font-weight: bold;
}

.about-rule {
   background-color: lightcoral;
}

.about-team-quote {
   color: #52c306;
   font-weight: bold;
}

.about-teams-info {
   display: flex;
   flex-direction: column;
   gap: 10px;
   flex-wrap: wrap;
}

.about-teams-text {
   align-items: center;
   text-align: center;
   display: flex;
   flex-direction: column;
}

.team-image {
   width: 250px;
}

.team-overlay {
   position: absolute;
   top: 0%;
   left: 80%;
   width: 100%;
   height: 0%;
   background-color: rgba(0, 0, 0, 0.7);
   display: flex;
   justify-content: center;
   align-items: center;
   overflow: hidden;
   transition: height 0.3s ease;
}

@media screen and (max-width: 700px) {
   .team-image {
      display: flex;
      flex-wrap: wrap;
   }
}