.home-detail-sections {
   width: 100%;
   padding: 10% 8%;
   display: flex;
   flex-direction: column;
   gap: 50px;
}

.home-detail-section {
   width: 100%;
   display: flex;
   gap: 10%;
}

.home-detail-section-image {
   height: 350px;
}

.home-detail-section-data {
   display: flex;
   flex-direction: column;
   gap: 8%;
   padding-top: 3%;
}

.home-detail-section-data-para {
   letter-spacing: 1px;
   width: 80%;
}

.home-detail-section-data-header {
   font-size: 2.5em;
   display: flex;
   flex-direction: column;
   gap: 5px;
}

.home-detail-section-data-quote {
   font-size: 15px;
   margin-left: 15px;
   color: gray;
}

.home-detail-section-data-button {
   display: flex;
   align-items: center;
   gap: 10px;
   font-size: 1.2em;
   cursor: pointer;
}

.home-detail-section-data-button-info {
   border-bottom: 1.5px solid black;
}

.home-detail-section-data-button-icon {
   color: #52c306;
   font-size: 25px;
}

.home-detail-section-data-button-info:hover {
   color: #52c306;
   border-bottom: 1.5px solid #52c306;
}

@media screen and (max-width: 700px) {
   .home-detail-section {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 30px;
      padding: 0;
   }

   .home-detail-section-data {
      display: flex;
      flex-direction: column;
      gap: 35px;
      padding-top: 3%;
   }

   .home-detail-section-data-header {
      font-size: 2em;
   }

   .home-detail-section-data-quote {
      font-size: 12px;
   }

   .home-detail-section-data-header {
      font-size: 1.4em;
      font-weight: 600;
   }

   .home-detail-section-image {
      width: 100%;
   }
}