.suki-home {
   width: 100%;
   display: flex;
   justify-content: center;
   gap: 50px;
   position: relative;
   z-index: -1;
   padding: 3% 20%;
}

.home-about-images {
   display: flex;
   flex-direction: column;
   gap: 20px;
   position: relative;
   z-index: -1;
   padding: 0 5%;
}

.home-about-image-one {
   height: 250px;
}

.home-about-image-two {
   position: absolute;
   height: 200px;
   top: 200px;
   left: -150px;
   box-shadow: 18px -18px 6px 5px #ffffff;
   margin-left: 10px;
}

.home-about-text {
   display: flex;
   flex-direction: column;
   gap: 40px;
   letter-spacing: 0.5px;
}

.home-about-text-info {
   display: flex;
   flex-direction: column;
   gap: 20px;
}

.home-about-text-info-header {
   color: #00b050;
   font-weight: 600;
}

.home-about-text-header-section {
   display: flex;
   flex-direction: column;
   gap: 5px;
}

.horizontal-rule {
   display: flex;
   align-items: center;
   text-align: center;
   gap: 2px;
}

hr {
   width: 60px;
   height: 4px;
   background-color: #00b050;
   border-color: #00b050;
}

.horizontal-rule-dotted {
   height: 4px;
   background-color: #00b050;
   width: 5px;
   border-color: #00b050;
}

.home-about-text-header {
   font-weight: bold;
   font-size: 40px;
}

.home-about-text-quote {
   color: #00b050;
}

.home-about-text-gifs {
   width: 100%;
   display: flex;
   justify-content: space-around;
}

.home-gifs {
   display: flex;
   flex-direction: column;
   padding: 15px 40px;
   text-align: center;
   gap: 25px;
   font-weight: bold;
}

.home-about-text-gif-one {
   height: 100px;
   width: 100px;
}

.vertical-line {
   border-left: 0.5px dashed gray;
   margin: 0 10px;
}

@media screen and (max-width: 700px) {
   .suki-home {
      width: 100%;
      display: flex;
      flex-direction: column-reverse;
      padding: 10px 0 30px 0;
   }

   .home-about-image-one {
      height: 300px;
      padding: 5px;
   }

   .home-about-image-two {
      visibility: hidden;
   }

   .home-about-text-info {
      display: flex;
      flex-direction: column;
      gap: 20px;
      font-size: 15px;
      padding: 5px;
   }

   .home-about-text-gifs {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 20px;
   }

   .home-gifs {
      padding: 0 35%;
   }
}