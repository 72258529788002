body {
   margin: 0;
   padding: 0;
   font-family: Arial, sans-serif;
}

.image-contianer {
   position: relative;
   width: 100%;
}

.top-image {
   width: 100%;
   height: 100vh;
   display: block;
   filter: blur(5px);
   -webkit-filter: blur(5px);
}

.home-image-text {
   background-color: rgba(0, 0, 0, 0.2);
   color: white;
   font-weight: bold;
   position: absolute;
   width: 65%;
   height: auto;
   top: 50%;
   left: 20%;
   text-align: center;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   gap: 20px;
   letter-spacing: 0.5px;
}

.prima {
   font-size: 35px;
   cursor: pointer;
}

.prima:hover {
   color: #52c306;
   transform: scale(1.5);
   transition: all ease-in-out;
   transition-duration: 1s;
   padding: 0;
}

.secondo {
   font-size: 60px;
}

@media screen and (max-width: 700px) {
   .prima {
      font-size: 15px;
   }

   .secondo {
      font-size: 20px;
   }
}