.about-home {
   width: 100%;
   display: flex;
   flex-direction: column;
   padding: 4% 5%;
   gap: 100px;
}

.about-home-text {
   width: 100%;
   display: flex;
   gap: 10%;
}

.about-header-text {
   display: flex;
   flex-direction: column;
   gap: 20px;
}

.about-home-text-header {
   width: 50%;
}

.about-home-text-data {
   width: 50%;
   display: flex;
   flex-direction: column;
   gap: 40px;
   letter-spacing: 1px;
   padding-top: 50px;
}

@media screen and (max-width: 700px) {
   .about-home {
      display: flex;
      flex-direction: column;
      gap: 30px;
   }

   .about-home-text {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 20px;
   }

   .about-home-text-header {
      width: 100%;
   }

   .about-home-text-data {
      width: 100%;
   }
}