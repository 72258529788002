.image-hover-container {
   display: flex;
   justify-content: center;
   align-items: center;
   gap: 20px;
   padding: 0;

}

.image-wrapper {
   position: relative;
   overflow: hidden;
   display: flex;
   cursor: pointer;
   border-radius: 20px;
   flex-direction: column;
}

.hover-image {
   width: 250px;
   height: 300px;
}

.overlay {
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 0;
   background-color: rgba(0, 0, 0, 0.7);
   display: flex;
   justify-content: center;
   align-items: center;
   overflow: hidden;
   transition: height 0.3s ease;
}

.overlay p {
   color: white;
   font-size: 18px;
}

.image-wrapper:hover .overlay {
   height: 100%;
}

.small {
   display: none;
}

@media screen and (max-width: 700px) {
   .image-hover-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 20px;
      padding: 0;
      margin: 0;
   }

   .full {
      display: none;
   }
}