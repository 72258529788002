*,
::after,
::before {
   margin: 0;
   padding: 0;
   box-sizing: border-box;
}

body {
   margin: 0;
   font-family: 'Roboto', sans-serif;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
   scroll-behavior: smooth;
}


@media screen and (max-width: 700px) {
   .container {
      margin-left: 0;
      width: 100%;
   }
}