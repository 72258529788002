.Footer {
   width: 100%;
   background-color: #112047;
   color: white;
   display: flex;
   flex-direction: column;
   justify-content: center;
   text-align: center;
}

.footer-solution {
   display: flex;
   gap: 5%;
   justify-content: center;
   text-align: center;
   margin: 4em 15em 0 15em;
   letter-spacing: 1px;
   padding-bottom: 4em;
   border-bottom: 1px solid gray;
   flex-wrap: wrap;
   background: transparent;
   row-gap: 15px;
}

.footer-sol {
   display: flex;
   flex-direction: column;
   align-items: center;
   gap: 20px;
}

.footer-sol>img {
   height: 80px;
   width: 70px;
}

.footer-links {
   display: flex;
   text-align: left;
   gap: 10%;
   justify-content: center;
   letter-spacing: 1px;
   margin-top: 2%;
   padding: 10px 0;
}

.footer-address {
   display: flex;
   align-items: center;
   margin-top: 13%;
   gap: 3px;
   cursor: pointer;
}

.footer-address-detail {
   display: flex;
   flex-direction: column;
   gap: 3px;
}

.footer-address-icon {
   height: 50px;
   width: 50px;
   color: orange;
}

.street {
   font-size: 20px;
   color: #fff;
   text-decoration: none;
}

.street:hover {
   text-decoration: underline;
}

.footer-quick {
   display: flex;
   align-items: center;
   gap: 10px;
   margin-top: 15%;
}

.footer-quick:hover {
   color: #52c306;
   cursor: pointer;
}

.footer-down {
   padding: 20px 0;
   display: flex;
   justify-content: space-evenly;
   align-items: center;
   color: gray;
   border-top: 1px solid gray;
}

.domain {
   color: white;
   margin-left: 5px;
}

@media screen and (max-width: 700px) {
   .Footer {
      margin: 0;
      width: 100%;
   }

   .footer-solution {
      display: flex;
      flex-direction: column;
      margin: 0;
      padding: 0;
      gap: 10%;
   }

   .footer-sol>img {
      height: 50px;
      width: 70px;
   }

   .footer-sol {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
      margin: 20px 0px;
   }

   .footer-links {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      text-align: left;
      padding: 0;
      font-size: 9px;
      gap: 20px;
   }

   .footer-address {
      margin-top: 2%;
      margin-left: 10px;
   }

   .footer-quick-links {
      width: 100%;
      border-bottom: 1px solid gray;
      padding-bottom: 20px;
   }

   .footer-quick {
      margin-top: 2%;
      font-size: 12px;
      margin-left: 10px;
   }

   .footer-contact-us {
      padding-bottom: 20px;
      border-bottom: 1px solid gray;
   }

   .footer-sol>span {
      font-size: 12px;
   }

   h2 {
      font-size: 17px;
      padding-left: 10px;
   }

   .street {
      font-size: 15px;
   }
}