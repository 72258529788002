.home-contact-middle {
   background-color: #24345d;
   color: white;
   display: flex;
   justify-content: center;
   align-items: center;
   font-size: 30px;
   gap: 10px;
   padding: 25px 5%;
}

.home-contact-header {
   width: 35%;
   line-height: 40px;
}

.home-contact-form-section {
   width: 50%;
}

.home-contact-form {
   display: flex;
   align-items: center;
   gap: 5%;
}

.home-contact-form-input {
   font-size: 15px;
   width: 17em;
   background-color: #24345d;
   border: 1px solid gray;
   color: white;
   padding: 15px;
}

input:focus {
   border-color: #08fdd8;
}

.submit {
   background-color: #0ebda2;
   color: black;
}

.submit:hover {
   transition: ease-in all;
   background-color: #52c306;
}

.home-contact-form-popup {
   margin-top: 10px;
   width: 100%;
   color: white;
   font-size: 25px;
   display: flex;
   justify-content: space-between;
   padding: 10px;
   border: 2px solid #08fdd8;
   background-color: transparent;
}

.close-button {
   cursor: pointer;
   font-size: 20px;
   color: #52c306;
   font-weight: 900;
}

@media screen and (max-width: 700px) {
   .home-contact-middle {
      display: flex;
      flex-direction: column;
      margin: 0;
      padding: 0;
      font-size: 20px;
      justify-content: flex-start;
      align-items: center;
      text-align: left;
      padding: 30px 20px 40px 5px;
      gap: 30px;
   }

   .home-contact-header {
      width: 100%;
      line-height: 25px;
      padding: 0 45px 5px 15px;
   }

   .home-contact-form {
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 20px;
   }

   .home-contact-form-input {
      font-size: 15px;
      width: 22.5em;
      background-color: #24345d;
      border: 1px solid gray;
      color: white;
      padding: 15px;
   }

   .home-contact-form-popup {
      margin-top: 10px;
      width: 12em;
      color: white;
      font-size: 20px;
      display: flex;
      justify-content: space-between;
      padding: 20px 10px;
      border: 2px solid #08fdd8;
   }

   .close-button {
      margin-top: -10%;
   }
}