.home-program {
   width: 100%;
   background: linear-gradient(to bottom,
         #dbdbe1 0%,
         #dbdbe1 70%,
         white 70%,
         white 100%);
   display: flex;
   flex-direction: column;
   position: relative;
   transition: ease-in-out;
   justify-content: center;
   gap: 50px;
   padding: 50px;
}

.home-program-quote {
   color: #00b050;
}

.home-program-header {
   display: flex;
   flex-direction: column;
   align-items: center;
   gap: 15px;
   margin-top: 2%;
}

.home-about-text-header-section {
   letter-spacing: 1px;
}

.home-program-header>span {
   color: #52c306;
   font-weight: 600;
}

@media screen and (max-width: 700px) {
   .home-program-header {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding-left: 15px;
   }

   .home-program-header>span {
      text-align: left !important;
      left: 0;
      color: #52c306;
      font-weight: 600;
   }

   .home-about-text-header {
      font-size: 2em;
   }
}