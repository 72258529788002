.contact-header {
   background-color: #505e7d;
   height: 70vh;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   width: 100%;
   color: #fff;
   gap: 25px;
   font-weight: 600;
}

.contact-header-prima {
   display: flex;
   align-items: center;
   gap: 5px;
   font-size: 20px;
   color: #fff;
}

.contact-header-secondo {
   font-size: 80px;
   letter-spacing: 0.5px;
}

.contact-middle {
   display: flex;
}



@media screen and (max-width: 700px) {
   .contact-header-prima {
      font-size: 20px;
   }

   .contact-header-secondo {
      font-size: 60px;
   }
}