.about-mission {
   width: 100%;
   display: flex;
}

.about-mission-text {
   width: 50%;
   background-color: #2c4444fa;
   color: #fff;
   display: flex;
   flex-direction: column;
   gap: 30px;
   padding: 2.5% 15% 0 5%;
}

.about-mission-text-header {
   font-size: 1em;
   font-weight: 600;
   cursor: pointer;
}

.about-mission-text-header:hover {
   color: #52c306;
   transition: all ease-in-out;
   transition-duration: 1s;
}

.about-mission-text-quote {
   font-size: 2.5em;
   font-weight: 600;
}

.about-mission-text-key {
   display: flex;
   flex-direction: column;
   gap: 15px;
   font-weight: 600;
   font-size: 1em;
}

.about-mission-text-key>span {
   display: flex;
   gap: 5px;

}

.about-mission>img {
   width: 25%;
   height: 500px;
}

.about-mission-text>p {
   letter-spacing: 1px;
   line-height: 22px;
   font-weight: normal;
}

.about-mission-text-button {
   display: flex;
   align-items: center;
   background-color: #52c306;
   color: #fff;
   border: none;
   gap: 20px;
   padding: 12px 25px;
   font-size: 16px;
   letter-spacing: 2px;
   font-weight: 500;
   width: 9em;
   cursor: pointer;
}

.about-mission-text-button:hover {
   background-color: black;
   transition: all ease-in-out;
   transition-duration: 1s;
}

@media screen and (max-width: 700px) {
   .about-mission {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 10px
   }

   .about-mission-text {
      width: 100%;
      padding: 5% 0 5% 5%;
   }

   .about-mission>img {
      width: 100%;
   }

   .about-mission-text-quote {
      font-size: 2em;
      font-weight: 600;
   }
}