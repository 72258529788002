.popup-container {
   position: fixed;
   top: 0%;
   left: 0%;
   right: 0%;
   bottom: 0%;
   color: black;
   z-index: 1;
}

.popup-body {
   width: auto;
   height: 150px;
   position: absolute;
   left: 30%;
   right: 30%;
   bottom: 30%;
   top: 30%;
   text-align: center;
   border-radius: 15px;
   border-color: black;
   background: white;
   box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
   display: flex;
   flex-direction: column;
   padding: 20px 0;
   justify-content: space-between;
}

.popup-body>button {
   width: 100px;
   padding: 10px;
   display: flex;
   align-items: center;
   text-align: center;
   justify-content: center;
   cursor: pointer;
   margin-left: 40%;
   font-size: 15px;
   background-color: #52c306;
   border: none;
}

@media screen and (max-width: 700px) {
   .popup-body {
      width: 300px;
      height: 150px;
      position: absolute;
      left: 10%;
      right: 30%;
      bottom: 30%;
      top: 30%;
      text-align: center;
      border-radius: 15px;
      border-color: black;
      background: white;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      display: flex;
      flex-direction: column;
      padding: 20px 0;
      justify-content: space-between;
   }

   .popup-body>button {
      width: 100px;
      padding: 8px;
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
      cursor: pointer;
      margin-left: 35%;
      font-size: 15px;
      background-color: #52c306;
      border: none;
      border-radius: 10px;
   }
}