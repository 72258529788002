.about-section {}

.about-header {
   background-color: #505e7d;
   height: 70vh;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   width: 100%;
   color: #fff;
   gap: 25px;
   font-weight: 600;
}

.about-header-prima {
   display: flex;
   align-items: center;
   gap: 5px;
   font-size: 20px;
}

.about-header-secondo {
   font-size: 80px;
   letter-spacing: 0.5px;
}

.about-middle {
   display: flex;
}


@media screen and (max-width: 700px) {
   .about-header-prima {
      font-size: 20px;
   }

   .about-header-secondo {
      font-size: 45px;
   }
}