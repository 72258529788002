.contact-middle {
   display: flex;
   flex-wrap: wrap;
   padding: 8% 12%;
   justify-content: center;
   gap: 5%;
   background-color: #fff;
   row-gap: 50px;

}

.contact-address {
   width: 280px;
   height: 250px;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   background-color: #fff;
   border: 1px solid transparent;
   box-shadow: 2px 2px 5px 5px rgba(0, 0, 0, 0.1);
   gap: 25px;
   font-weight: 500;
   font-size: 20px;
   cursor: pointer;
}

.contact-address:hover {
   transition: all ease-in-out;
   transform: scale(1.05);
   transition-duration: 1s;
}

.contact-address-icon {
   font-size: 50px;
   color: orange;
}

.contact-street {
   font-size: 25px;
   text-align: center;
}